import * as gmapsLoader from "@googlemaps/js-api-loader";
import { navigate } from "astro:transitions/client";
import React, { useEffect, useRef, useState } from "react";
import useAlerts from "../hooks/useAlerts";
import { routes } from "../routes";
const { Loader } = gmapsLoader;

const Search = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const { addAlert } = useAlerts();
  const [keepMenuOpen, setKeepMenuOpen] = useState(false);

  useEffect(() => {
    const options = {
      componentRestrictions: { country: "se" },
    };

    const loader = new Loader({
      apiKey: import.meta.env.PUBLIC_GOOGLE_API_KEY,
      version: "weekly",
      libraries: ["places"],
      region: "SE",
    });

    loader.importLibrary("places").then(({ Autocomplete }) => {
      if (inputRef.current) {
        const newAutocomplete = new Autocomplete(inputRef.current, options);
        autocompleteRef.current = newAutocomplete;

        // Handle place selection
        newAutocomplete.addListener("place_changed", () => {
          const place = newAutocomplete.getPlace();
          if (place && place.geometry) {
            navigate(
              routes.LunchQuery(
                place.name!,
                place.geometry.location.lat().toString()!,
                place.geometry.location.lng().toString()!
              )
            );
          } else {
            addAlert(
              "Du måste välja en plats från förslagen",
              "invalidSelection",
              "info",
              10000
            );
          }
        });
      }
    });
  }, []);

  const getCurrentLocation = () => {
    if (!navigator.geolocation) {
      addAlert(
        "Geolocation is not supported by this browser.",
        "LocationNotSupported",
        "warning",
        10000
      );
      return;
    }

    navigator.geolocation.getCurrentPosition(
      async ({ coords: { latitude, longitude } }) => {
        try {
          const response = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
          );
          const data = await response.json();
          const city = data?.address?.city || "Unknown Location";

          navigate(
            routes.LunchQuery(city, latitude.toString(), longitude.toString())
          );
        } catch (error) {
          addAlert(
            "Failed to retrieve location metadata.",
            "GeocodeError",
            "error",
            10000
          );
        }
      },
      (error) => {
        addAlert(
          `Location access error: ${error.message}`,
          "LocationError",
          "warning",
          10000
        );
      }
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();

      // If the autocomplete menu is already open and no place is selected
      if (keepMenuOpen) {
        addAlert(
          "Du måste välja en plats från förslagen",
          "invalidSelection",
          "info",
          10000
        );
        return;
      }

      // Trigger autocomplete programmatically
      if (inputRef.current && autocompleteRef.current) {
        const input = inputRef.current;

        // Force the autocomplete menu to open
        setKeepMenuOpen(true);

        // Simulate a keyboard event to trigger the autocomplete
        const event = new KeyboardEvent('keydown', {
          key: 'ArrowDown',
          bubbles: true
        });
        input.dispatchEvent(event);
      }
    }
  };

  // Handle input changes to reset the keepMenuOpen state
  const handleInputChange = () => {
    setKeepMenuOpen(false);
  };

  return (
    <div className="w-full md:w-1/2">
      <form className="flex items-center">
        <label htmlFor="voice-search" className="sr-only">
          Search
        </label>
        <div className="relative w-full">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <svg
              aria-hidden="true"
              className="text-gray-500 dark:text-gray-400 h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <input
            type="text"
            id="voice-search"
            className="text-gray-900 placeholder-gray-500 block w-full rounded-full border-0 p-2.5 px-4 py-3 pl-10 text-base focus:outline-none focus:ring-0 focus:border-gray-300 hover:shadow-2xl"
            placeholder="Hitta lunch nära dig"
            required
            ref={inputRef}
            onKeyDown={handleKeyDown}
            onChange={handleInputChange}
          />
          <button
            type="button"
            className="absolute inset-y-0 right-0 flex items-center pr-3"
            onClick={getCurrentLocation}
            title="Använd min plats"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="text-gray-500 dark:text-gray-400 hover:text-gray-900 h-5 w-5"
            >
              <path
                fillRule="evenodd"
                d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Search;